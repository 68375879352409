<template><div><iframe src="https://forms.monday.com/forms/embed/d64f1c1825d2dbab5aa5a76a176fc9a3?r=use1" :width="width" :height="height" style="border: 0; box-shadow: 5px 5px 56px 0px rgba(0,0,0,0.25);"></iframe></div></template>
<script>
  export default {
    name: 'B2BWaveInvoice',
    computed: {
      width () {
        return document.body.scrollWidth
      },
      height () {
        return document.body.scrollHeight
      },
    },
  }
</script>